import React from "react";
import { Container, Col, Row, Button } from "react-bootstrap";
import { useAsyncSetState } from "use-async-setstate";
import { getOperationName } from "apollo-utilities";
import { useMutation } from "@apollo/client";

import Modal from "../bootstrap/modal";
import { acceptTNCMutation, getCurrentUserQuery } from "../logic/user";
import Terms from "../portal/terms";
import PrivacyPolicy from "../portal/privacy-policy";

export default function TNC() {
  const [show, setShow] = useAsyncSetState(true);
  const [checked, setCheck] = useAsyncSetState(false);
  const [acceptTNC] = useMutation(acceptTNCMutation);
  const [showTerm, setTerm] = useAsyncSetState(false);
  const [showPolicy, setPolicy] = useAsyncSetState(false);

  return (
    <Modal
      show={show}
      title={"Accept Terms & Conditions"}
      onClose={() => null}
      footer={
        <Container>
          <Row className="vw-portal-button-popup-row justify-content-end">
            <Col xs="auto ml-auto">
              <Button
                variant="yellow zolt-blue ml-auto"
                disabled={!checked}
                onClick={async () => {
                  await acceptTNC({
                    awaitRefetchQueries: true,
                    refetchQueries: [getOperationName(getCurrentUserQuery)],
                  });
                  return setShow(false);
                }}
              >
                {"Accept"}
              </Button>
            </Col>
          </Row>
        </Container>
      }
    >
      <Container fluid className="tnc-modal">
        {showTerm && <Terms onClose={() => setTerm(false)} />}
        {showPolicy && <PrivacyPolicy onClose={() => setPolicy(false)} />}
        <div className="vw-checkbox" onClick={async () => setCheck(!checked)}>
          <i
            onClick={async () => setCheck(!checked)}
            style={{ fontSize: 18 }}
            className={`mr-2 ${
              checked ? "far fa-check-square" : "far fa-square"
            }`}
          />
          {"I have read and agree to the "}
          <a onClick={() => setTerm(true)} className="mx-1">
            {"Terms of Service"}
          </a>
          {" and "}
          <a onClick={() => setPolicy(true)} className="mx-1">
            {"Privacy Policy"}
          </a>
          .
        </div>
      </Container>
    </Modal>
  );
}
