import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Doughnut } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

export default function Usage(props) {
  const { user } = props;
  const activePlan = user.activePlan;
  const { unlimited, total = 0, usage = 0 } = activePlan || {};
  let data = unlimited ? [1] : [usage, Number(total || 0) - Number(usage) || 0];
  if (!unlimited && usage === 0 && total === 0) {
    data = [0, 1];
  }
  return (
    <Col md={12} lg={4} className="mb-3">
      <div className="panel-container h-100">
        <div className="panel-header">
          <div>{"Usage"}</div>
        </div>
        <div className="panel-body period h-100">
          <div style={{minHeight: 339}}>
            <Doughnut
              plugins={[ChartDataLabels]}
              data={{
                datasets: [
                  {
                    data: activePlan ? data : [1],
                    backgroundColor: unlimited
                      ? ["rgb(78, 185, 231)"]
                      : ["rgb(78, 185, 231)", "rgb(150, 150, 150)"],
                  },
                ],
                labels: activePlan
                  ? unlimited
                    ? ["Unlimited"]
                    : ["Data Usage", "Remaining Data"]
                  : ["No Data"],
              }}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                  position: "bottom",
                },
                plugins: {
                  datalabels: {
                    backgroundColor: function (context) {
                      return context.dataset.backgroundColor;
                    },
                    color: "white",
                    display: false,
                    font: {
                      weight: "bold",
                    },
                  },
                },
                scales: {
                  display: {},
                  gridLines: {
                    lineWidth: 0,
                  },
                  ticks: {
                    fontFamily: "Roboto",
                  },
                },
                pan: {
                  enabled: true,
                  mode: "x",
                },
              }}
            />
          </div>
        </div>
      </div>
    </Col>
  );
}
