import React from "react";
import { Col } from "react-bootstrap";
import { Doughnut } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

export default function ConnectedDevices(props) {
  const {user} = props;

  const devices = (((user || {}).devices || {}).edges || []).map(({ node }) => node);

  const rawManufacturers = devices.map(({ manufacturer }) => manufacturer || "Others");
  const manufacturers = (rawManufacturers || []).filter((v, i, a) => a.indexOf(v) === i);
  const manufacturerDataSet = manufacturers.map((m) => {
    let count = 0;
    rawManufacturers.forEach((data) => {
      if (m === data) {
        count += 1;
      }
    });
    return count;
  });

  return (
    <Col md={12} lg={4} className="mb-3">
      <div className="panel-container h-100">
        <div className="panel-header">{"Total Connected Devices"}</div>
        <div className="panel-body period h-100">
          <div style={{minHeight: 339}}>
            {manufacturerDataSet.length !== 0 && <Doughnut
              plugins={[ChartDataLabels]}
              data={
                {
                  datasets: [{
                    data: manufacturerDataSet,
                    backgroundColor: ["rgb(240, 82, 35)", "rgb(37, 37, 37)", "rgb(159, 159, 159)"],
                  }],
                  labels: manufacturers
                }
              }
              options={{
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                  position: "bottom",
                },
                plugins: {
                  datalabels: {
                    backgroundColor: function (context) {
                      return context.dataset.backgroundColor;
                    },
                    color: "white",
                    font: {
                      weight: "bold",
                    },
                  },
                },
                scales: {
                  display: {},
                  gridLines: {
                    lineWidth: 0,
                  },
                  ticks: {
                    fontFamily: "Roboto",
                  },
                },
                pan: {
                  enabled: true,
                  mode: "x",
                },
              }}
            />}
          </div>
        </div>
      </div>
    </Col>
  );
}
