import React from "react";
import { Col, Container, Row, Button } from "react-bootstrap";
import { useAsyncSetState } from "use-async-setstate";
import { getOperationName } from "apollo-utilities";

import Modal from "../bootstrap/modal";
import {
  cancelUseOrderMutation,
  getCancelUserOrderResult,
  getCurrentUserQuery,
} from "../logic/user";

import { useMutation } from "@apollo/client";
import Loader from "../bootstrap/loader";

export default function CancelAccount(props) {
  const { onClose, selectedCancelOrder } = props;
  const [state, setState] = useAsyncSetState({
    processing: false,
    error: false,
  });

  const [cancelUserRenewal] = useMutation(cancelUseOrderMutation);

  if (!selectedCancelOrder) {
    return onClose();
  }

  async function handleCancelPlan() {
    try {
      await setState((prevState) => ({ ...prevState, processing: true }));

      const response = await cancelUserRenewal({
        variables: {
          orderId: selectedCancelOrder?.id,
        },
        awaitRefetchQueries: true,
        refetchQueries: [getOperationName(getCurrentUserQuery)],
      });

      // const response = await fetch.gql(cancelRenewalMutation, {
      //   userId: user.id,
      //   userRenewalId: user.activeRenewal.id,
      //   email: user.email,
      // });
      const result = getCancelUserOrderResult(response);

      if (result) {
        return onClose();
      }

      return setState((prevState) => ({
        ...prevState,
        error: false,
        processing: false,
      }));
    } catch (err) {
      return setState((prevState) => ({
        ...prevState,
        error: true,
        message:
          err.message.replace(/(GraphQL error:)/gi, "") ||
          "An error has occurred. Please contact support@vostronet.com",
      }));
    }
  }

  return (
    <Modal
      title={"Cancel Account"}
      onClose={onClose}
      show
      footer={
        <Row>
          <Col xs="auto" className="ml-auto" />
          <Col xs="auto">
            <Button
              variant="darkblue"
              type="button"
              disabled={state.processing || state.selectedProduct === null}
              onClick={() => handleCancelPlan()}
            >
              {"Confirm"}
            </Button>
          </Col>
        </Row>
      }
    >
      <Container fluid>
        <Row>
          <Col>
            <div style={{ paddingTop: 10 }}>
              <div>
                {state.processing ? (
                  <div>
                    <Loader />
                    {"Processing"}
                  </div>
                ) : (
                  "Are you sure you wish to cancel your plan?"
                )}
              </div>
            </div>
          </Col>
        </Row>
        {state.error && (
          <Row>
            <Col>
              <div style={{ color: "red", overflowWrap: "break-word" }}>
                {state.message}
              </div>
            </Col>
          </Row>
        )}
      </Container>
    </Modal>
  );
}
