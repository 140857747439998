import React, {useState} from "react";
import {Navbar, Nav, Container, NavDropdown} from "react-bootstrap";
import {
  useMutation,
} from "@apollo/client";
import {localStorage, window} from "window-or-global";

import {logoutMutation} from "../logic/user";
import Main from "../main";
import Logo from "../../../static/content/zolt-logo.png";
import {Fade as Burger} from "../react-burger";
import LogoutIcon from "../../images/logout.svg";
import AuthIcon from "../../images/icon_authenticator.svg";

export default function Index(props) {
  return (
    <Main>
      <Header {...props} />
    </Main>
  );
}

function Header() {
  const [logout] = useMutation(logoutMutation);
  const [showMenu, setShowMenu] = useState(false);

  async function onHandleLogout() {
    await logout();
    localStorage.removeItem("authToken");
    window.location.href = "/login/";
  }

  return (
    <header className="portal-header">
      <Container>
        <Navbar variant="light mh-120" expand="lg">
          <Navbar.Brand href="/">
            <img src={Logo} alt="logo" className="header-logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav">
            <Burger direction="right"/>
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto font-white align-items-center">
              <Nav.Link className="ph-link" href="https://www.zolt.com.au/?section=about">{"About"}</Nav.Link>
              <Nav.Link className="ph-link" href="https://www.zolt.com.au/?section=plans">{"Plans"}</Nav.Link>
              <Nav.Link className="ph-link" href="https://www.zolt.com.au/?section=why">{"Why Zolt?"}</Nav.Link>
              <Nav.Link className="ph-link" href="https://www.zolt.com.au/?section=contact">{"Contact Us"}</Nav.Link>
              <Nav.Link className="ph-link" href="https://www.zolt.com.au/?section=faqs">{"FAQs"}</Nav.Link>
              <NavDropdown className="portal-header-dropdown" title={<Burger direction="right"/>} onClick={() => setShowMenu(prev => !prev)} show={showMenu}>
                <NavDropdown.Item href="/mfa"><img src={AuthIcon}/>{"2-Step Verification"}</NavDropdown.Item>
                <NavDropdown.Item onClick={() => onHandleLogout()}><img src={LogoutIcon}/>{"Logout"}</NavDropdown.Item>
              </NavDropdown>
              <Nav.Link className="ph-link hidden-on-expand" href="/mfa">{"2-Step Verification"}</Nav.Link>
              <Nav.Link className="ph-link hidden-on-expand" onClick={() => onHandleLogout()}>{"Logout"}</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </Container>
    </header>
  );
}
